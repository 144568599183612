import React, { useContext, useState } from "react";
import { Button, Dropdown, Menu } from "antd";
import { AuthContext } from "../../../contexts/AuthContext";
import "./Main.css";
import * as links from "../../../utils/links";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faServer,
  faXmark,
  faBars,
  faRightFromBracket,
} from "@fortawesome/free-solid-svg-icons";
import {
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerOverlay,
  Flex,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import {
  FaBars,
  FaBitcoin,
  FaBook,
  FaHouseUser,
  FaLink,
  FaStore,
  FaUser,
} from "react-icons/fa";
import { SettingFilled } from "@ant-design/icons";
import { FiLogOut } from "react-icons/fi";

const Main = (props) => {
  const { children } = props;
  const { setDataUser, loading, authInfo } = useContext(AuthContext);

  const location = useLocation();
  let navigate = useNavigate();

  const { dataUser } = authInfo;

  const { isOpen, onOpen, onClose } = useDisclosure();
  const btnRef = React.useRef();

  const menu = (
    <Menu
      items={[
        {
          key: "1",
          label: (
            <div
              onClick={() => {
                setDataUser(null);
              }}
            >
              Sair
            </div>
          ),
        },
      ]}
    />
  );
  return (
    <>
      <Flex width="100%" backgroundColor="#161920" flexDirection="column">
        <Flex padding="10px 20px">
          <FaBars onClick={onOpen} color="white" fontSize="24px" />
          <Drawer isOpen={isOpen} placement="left" onClose={onClose} size="xs">
            <DrawerOverlay />
            <DrawerContent backgroundColor="#161920" style={{ width: "220px" }}>
              <DrawerCloseButton color="white" />
              <DrawerBody
                paddingTop="50px"
                display="flex"
                flexDirection="column"
                gap="20px"
              >
                <Flex
                  width="100%"
                  gap="20px"
                  alignItems="center"
                  onClick={() => {
                    navigate(
                      dataUser.key === "ADMIN"
                        ? "/dashboard-clientes"
                        : "/dashboard-maquinas"
                    );
                    onClose();
                  }}
                  cursor="pointer"
                >
                  <FaHouseUser color="white" fontSize="20px" />
                  <span style={{ color: "white" }}>Visão Geral</span>
                </Flex>
                <Flex
                  width="100%"
                  gap="20px"
                  alignItems="center"
                  onClick={() => {
                    navigate("/modulos");
                    onClose();
                  }}
                  cursor="pointer"
                >
                  <SettingFilled
                    style={{ color: "white", fontSize: "20px" }}
                    color="white"
                  />
                  <span style={{ color: "white" }}>Módulos</span>
                </Flex>
                <Flex
                  width="100%"
                  gap="20px"
                  alignItems="center"
                  onClick={() => {
                    navigate("/vendas");
                    onClose();
                  }}
                  cursor="pointer"
                >
                  <FaBitcoin
                    style={{ color: "white", fontSize: "20px" }}
                    color="white"
                  />
                  <span style={{ color: "white" }}>Vendas</span>
                </Flex>
                <Flex
                  width="100%"
                  gap="20px"
                  alignItems="center"
                  onClick={() => {
                    navigate("/lojas");
                    onClose();
                  }}
                  cursor="pointer"
                >
                  <FaStore
                    style={{ color: "white", fontSize: "20px" }}
                    color="white"
                  />
                  <span style={{ color: "white" }}>Lojas</span>
                </Flex>
                <Flex
                  width="100%"
                  gap="20px"
                  alignItems="center"
                  onClick={() => {
                    navigate("/telemetria");
                    onClose();
                  }}
                  cursor="pointer"
                >
                  <FaLink
                    style={{ color: "white", fontSize: "20px" }}
                    color="white"
                  />
                  <span style={{ color: "white" }}>Telemetria</span>
                </Flex>
                <Flex
                  width="100%"
                  gap="20px"
                  alignItems="center"
                  onClick={() => {
                    localStorage.clear();
                    window.location.reload();
                    navigate("/");
                  }}
                  cursor="pointer"
                  marginTop="50px"
                >
                  <FiLogOut
                    style={{ color: "white", fontSize: "20px" }}
                    color="white"
                  />
                  <span style={{ color: "white" }}>Sair</span>
                </Flex>
              </DrawerBody>
            </DrawerContent>
          </Drawer>
        </Flex>

        <div style={{ width: "100%", padding: "20px" }}>
          <div>{children}</div>
        </div>
      </Flex>
    </>
  );
};

export default Main;
