import React from "react";
import "./Auth.css";
import { Button, Checkbox, Col, Input, Row } from "antd";
import {
  EyeInvisibleOutlined,
  EyeTwoTone,
  FacebookOutlined,
} from "@ant-design/icons";
import { FiLogIn } from "react-icons/fi";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebook,
  faInstagram,
  faWhatsapp,
} from "@fortawesome/free-brands-svg-icons";
import { Flex, Text, useMediaQuery } from "@chakra-ui/react";
import logoPix from "../../../assets/images/logoPix.png";

const Auth = (props) => {
  const [isLargerThan800] = useMediaQuery("(min-width: 800px)");
  const {
    authTitle,
    authDescription,
    authFields,
    authSubmit,
    authImage,
    onsubmit,
    successMessage,
    errorMessage,
    textImage,
  } = props;
  return (
    <Flex
      width="100%"
      height="100vh"
      justifyContent="center"
      alignItems="center"
      backgroundColor="#161920"
      flexDirection="column"
      gap="60px"
    >
      <img src={logoPix} alt="logo" style={{ width: "250px" }} />
      <form
        onSubmit={onsubmit}
        style={{
          backgroundColor: "#1F2630",
          padding: "30px",
          display: "flex",
          flexDirection: "column",
          gap: "20px",
          borderRadius: "30px",
          border: "1px solid white",
          minWidth: isLargerThan800 ? "400px" : "90%",
        }}
      >
        {authFields.map((itemField, indexField) => {
          switch (itemField.type) {
            case "text":
              return (
                <div key={indexField}>
                  <Input
                    placeholder={itemField.placeholder ?? ""}
                    value={itemField.value}
                    id="username"
                    type="text"
                    name="username"
                    autoComplete="username"
                    onChange={(event) => {
                      if (typeof itemField.setField === "function") {
                        itemField.setField(event.target.value);
                      }
                    }}
                    onKeyPress={(event) => {
                      if (event.key === "Enter") {
                        onsubmit();
                      }
                    }}
                  />
                  {itemField.error && (
                    <div
                      className="Admin_Auth_itemFieldError"
                      style={{ color: "#e46772" }}
                    >
                      {itemField.error}
                    </div>
                  )}
                </div>
              );
            case "password":
              return (
                <div key={indexField}>
                  <Input.Password
                    placeholder={itemField.placeholder ?? ""}
                    id="password"
                    value={itemField.value}
                    type="password"
                    name="password"
                    autoComplete="current-password"
                    iconRender={(visible) =>
                      visible ? (
                        <FontAwesomeIcon icon={faEyeSlash}></FontAwesomeIcon>
                      ) : (
                        <FontAwesomeIcon icon={faEye}></FontAwesomeIcon>
                      )
                    }
                    onChange={(event) => {
                      if (typeof itemField.setField === "function") {
                        itemField.setField(event.target.value);
                      }
                    }}
                    onKeyPress={(event) => {
                      if (event.key === "Enter") {
                        onsubmit();
                      }
                    }}
                  />
                  {itemField.error && (
                    <div style={{ color: "#e46772", fontWeight: 400 }}>
                      {itemField.error}
                    </div>
                  )}
                </div>
              );
            default:
              return null;
          }
        })}
        <Button
          onClick={() => {
            if (typeof onsubmit === "function") {
              onsubmit();
            }
          }}
        >
          {authSubmit.toUpperCase()}
        </Button>
      </form>

      {successMessage && (
        <div className="Admin_Auth_successMessage">{successMessage}</div>
      )}
      {errorMessage && (
        <div className="Admin_Auth_errorMessage">
          <div
            dangerouslySetInnerHTML={{
              __html: "Erro, algo deu errado Senha ou Email Inválido.",
            }}
          ></div>
        </div>
      )}
    </Flex>
  );
};

export default Auth;
