import React, { useContext, useEffect, useState } from "react";
import "./ClientesDashboard.css";
import { Button, Col, Row } from "antd";
import axios from "axios";
import * as links from "../../../../utils/links";
import { AuthContext } from "../../../../contexts/AuthContext";
import { Link, useNavigate } from "react-router-dom";
import LoadingAction from "../../../../themes/LoadingAction/LoadingAction";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowsRotate } from "@fortawesome/free-solid-svg-icons";
import { AiOutlinePlusCircle } from "react-icons/ai";
import { format, parseISO } from "date-fns";
import {
  Flex,
  Popover,
  PopoverArrow,
  PopoverContent,
  PopoverTrigger,
  Tab,
  Table,
  TableContainer,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import { FaBars, FaEdit, FaEye, FaTrash, FaWifi } from "react-icons/fa";
import { PopoverBody } from "react-bootstrap";

const ClientesDashboard = (props) => {
  const { setDataUser, authInfo, setNotiMessage } = useContext(AuthContext);
  const { dataUser } = authInfo;
  let navigate = useNavigate();

  const token = authInfo?.dataUser?.token;

  const [totalClientes, setTotalClientes] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    dataData();

    const intervalId = setInterval(() => {
      dataData();
    }, 60000);

    return () => clearInterval(intervalId);
  }, []);

  const dataData = () => {
    setIsLoading(true);
    axios
      .get(`${process.env.REACT_APP_SERVIDOR}/clientes`, {
        headers: {
          "x-access-token": token,
          "content-type": "application/json",
        },
      })
      .then((res) => {
        if (res.status === 200) {
          setIsLoading(false);
          setTotalClientes(res.data);
        } else {
          throw new Error();
        }
      })
      .catch((err) => {
        setIsLoading(false);
        if ([401, 403].includes(err.response.status)) {
          // setNotiMessage('A sua sessão expirou, para continuar faça login novamente.');
          setNotiMessage({
            type: "error",
            message:
              "A sua sessão expirou, para continuar faça login novamente.",
          });
          setDataUser(null);
        }
      });
  };

  const handleClienteClick = ({
    id,
    nome,
    email,
    ativo,
    dataInclusao,
    dataVencimento,
    ultimoAcesso,
    mercadoPagoToken,
  }) => {
    const cliente = {
      id,
      nome,
      email,
      ativo,
      dataInclusao,
      dataVencimento,
      ultimoAcesso,
      mercadoPagoToken,
    };
    navigate(`${links.CLIENTES_MAQUINAS}/${id}`, {
      state: cliente,
    });
  };

  const clientesInadiplentes = totalClientes.filter(({ dataVencimento }) => {
    let dataVencimentoVal = 0;
    const dt = new Date(dataVencimento);
    const dtDateOnly = new Date(
      dt.valueOf() + dt.getTimezoneOffset() * 60 * 1000
    );
    if (dataVencimento) {
      let dataVencimentoDate = new Date(dataVencimento);
      dataVencimentoVal = dataVencimentoDate.getTime() ?? 0;
    }

    const diferencaEmMilissegundos = new Date().getTime() - dataVencimentoVal;
    const diferencaEmDias = Math.floor(
      diferencaEmMilissegundos / (1000 * 60 * 60 * 24)
    );

    return diferencaEmDias > 10;
  });

  const clientesRegulares = totalClientes.filter(({ dataVencimento }) => {
    let dataVencimentoVal = 0;
    const dt = new Date(dataVencimento);
    const dtDateOnly = new Date(
      dt.valueOf() + dt.getTimezoneOffset() * 60 * 1000
    );
    if (dataVencimento) {
      let dataVencimentoDate = new Date(dataVencimento);
      dataVencimentoVal = dataVencimentoDate.getTime() ?? 0;
    }

    const diferencaEmMilissegundos = new Date().getTime() - dataVencimentoVal;
    const diferencaEmDias = Math.floor(
      diferencaEmMilissegundos / (1000 * 60 * 60 * 24)
    );

    return diferencaEmDias < 10;
  });

  return (
    <Flex minH="100vh" flexDirection="column">
      <Text color="white" fontSize="24px">
        Clientes
      </Text>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          marginBottom: "20px",
          gap: "20px",
        }}
      >
        <Button onClick={dataData} style={{ borderRadius: "8px" }}>
          <FontAwesomeIcon
            icon={faArrowsRotate}
            style={{ marginRight: "5px" }}
          />
          Atualizar
        </Button>
        <Link to={links.ADD_CLIENTES}>
          <Button
            className="Cliente_Dashboard_addbtn"
            style={{ borderRadius: "8px" }}
          >
            <AiOutlinePlusCircle />
            <span>Criar novo cliente</span>
          </Button>
        </Link>
      </div>
      <Tabs>
        <TabList borderColor="#1F2630">
          <Tab
            color="white"
            _selected={{ borderColor: "white" }}
            fontSize="14px"
          >
            Todos{" "}
            <span
              style={{
                marginLeft: "10px",
                padding: "5px 10px",
                backgroundColor: "#01B0D1",
                borderRadius: "8px",
                fontSize: "12px",
              }}
            >
              {totalClientes.length}
            </span>
          </Tab>
          <Tab
            color="white"
            _selected={{ borderColor: "white" }}
            fontSize="14px"
          >
            Regulares{" "}
            <span
              style={{
                marginLeft: "10px",
                padding: "5px 10px",
                backgroundColor: "#21BD52",
                borderRadius: "8px",
                fontSize: "12px",
              }}
            >
              {clientesRegulares.length}
            </span>
          </Tab>
          <Tab
            color="white"
            _selected={{ borderColor: "white" }}
            fontSize="14px"
          >
            Inadimplentes
            <span
              style={{
                marginLeft: "10px",
                padding: "5px 10px",
                backgroundColor: "#F45031",
                borderRadius: "8px",
                fontSize: "12px",
              }}
            >
              {clientesInadiplentes.length}
            </span>
          </Tab>
        </TabList>

        <TabPanels>
          <TabPanel>
            <TableContainer minH="500px">
              <Table size="sm">
                <Thead>
                  <Tr>
                    <Th borderColor="#1F2630"></Th>
                    <Th borderColor="#1F2630">Nome</Th>
                    <Th borderColor="#1F2630">Email</Th>
                    <Th borderColor="#1F2630">Total de máquinas</Th>
                    <Th borderColor="#1F2630">Data inclusão</Th>
                    <Th borderColor="#1F2630">Último acesso</Th>
                    <Th borderColor="#1F2630">Data vencimento</Th>
                    <Th borderColor="#1F2630">Ativo</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {totalClientes.map(
                    ({
                      id,
                      nome,
                      email,
                      dataInclusao,
                      dataVencimento,
                      ultimoAcesso,
                      Maquina,
                      ativo,
                      mercadoPagoToken,
                    }) => {
                      const machinesTotal = Maquina.length;
                      const dt = new Date(dataVencimento);
                      const dtDateOnly = new Date(
                        dt.valueOf() + dt.getTimezoneOffset() * 60 * 1000
                      );
                      return (
                        <Tr key={id}>
                          <Td borderColor="#1F2630">
                            <Popover placement="bottom-end">
                              <PopoverTrigger>
                                <Button
                                  style={{
                                    backgroundColor: "transparent",
                                    border: "none",
                                  }}
                                  _active={{ backgroundColor: "transparent" }}
                                  _hover={{ backgroundColor: "transparent" }}
                                >
                                  <FaBars color="white" />
                                </Button>
                              </PopoverTrigger>
                              <PopoverContent maxW="180px">
                                <PopoverArrow />
                                <PopoverBody>
                                  <Flex
                                    gap="10px"
                                    onClick={() =>
                                      handleClienteClick({
                                        id,
                                        nome,
                                        email,
                                        ativo,
                                        dataInclusao,
                                        dataVencimento,
                                        ultimoAcesso,
                                        mercadoPagoToken,
                                      })
                                    }
                                  >
                                    <FaEye />
                                    Ver mais
                                  </Flex>
                                  <Flex gap="10px" marginTop="10px" color="red">
                                    <FaTrash />
                                    Excluir
                                  </Flex>
                                </PopoverBody>
                              </PopoverContent>
                            </Popover>
                          </Td>
                          <Td color="white" borderColor="#1F2630">
                            {nome}
                          </Td>
                          <Td borderColor="#1F2630" color="white">
                            {email}
                          </Td>
                          <Td color="white" borderColor="#1F2630">
                            {machinesTotal}
                          </Td>
                          <Td color="white" borderColor="#1F2630">
                            {format(
                              new Date(dataInclusao),
                              "dd/MM/yyyy - kk:mm"
                            )}
                          </Td>
                          <Td color="white" borderColor="#1F2630">
                            {ultimoAcesso
                              ? format(
                                  new Date(ultimoAcesso),
                                  "dd/MM/yyyy - kk:mm"
                                )
                              : "--"}
                          </Td>
                          <Td color="white" borderColor="#1F2630">
                            {format(dtDateOnly, "dd/MM/yyyy")}
                          </Td>
                          <Td color="white" borderColor="#1F2630">
                            {ativo ? (
                              <div
                                style={{
                                  width: "15px",
                                  height: "15px",
                                  borderRadius: "50%",
                                  backgroundColor: "green",
                                }}
                              ></div>
                            ) : (
                              <div
                                style={{
                                  width: "15px",
                                  height: "15px",
                                  borderRadius: "50%",
                                  backgroundColor: "red",
                                }}
                              ></div>
                            )}
                          </Td>
                        </Tr>
                      );
                    }
                  )}
                </Tbody>
              </Table>
            </TableContainer>
          </TabPanel>
          <TabPanel>
            <TableContainer minH="500px">
              <Table size="sm">
                <Thead>
                  <Tr>
                    <Th borderColor="#1F2630"></Th>
                    <Th borderColor="#1F2630">Nome</Th>
                    <Th borderColor="#1F2630">Email</Th>
                    <Th borderColor="#1F2630">Total de máquinas</Th>
                    <Th borderColor="#1F2630">Data inclusão</Th>
                    <Th borderColor="#1F2630">Último acesso</Th>
                    <Th borderColor="#1F2630">Data vencimento</Th>
                    <Th borderColor="#1F2630">Ativo</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {clientesRegulares.map(
                    ({
                      id,
                      nome,
                      email,
                      dataInclusao,
                      dataVencimento,
                      ultimoAcesso,
                      Maquina,
                      ativo,
                      mercadoPagoToken,
                    }) => {
                      const machinesTotal = Maquina.length;
                      const dt = new Date(dataVencimento);
                      const dtDateOnly = new Date(
                        dt.valueOf() + dt.getTimezoneOffset() * 60 * 1000
                      );
                      return (
                        <Tr key={id}>
                          <Td borderColor="#1F2630">
                            <Popover placement="bottom-end">
                              <PopoverTrigger>
                                <Button
                                  style={{
                                    backgroundColor: "transparent",
                                    border: "none",
                                  }}
                                  _active={{ backgroundColor: "transparent" }}
                                  _hover={{ backgroundColor: "transparent" }}
                                >
                                  <FaBars color="white" />
                                </Button>
                              </PopoverTrigger>
                              <PopoverContent maxW="180px">
                                <PopoverArrow />
                                <PopoverBody>
                                  <Flex
                                    gap="10px"
                                    onClick={() =>
                                      handleClienteClick({
                                        id,
                                        nome,
                                        email,
                                        ativo,
                                        dataInclusao,
                                        dataVencimento,
                                        ultimoAcesso,
                                        mercadoPagoToken,
                                      })
                                    }
                                  >
                                    <FaEye />
                                    Ver mais
                                  </Flex>
                                  <Flex gap="10px" marginTop="10px" color="red">
                                    <FaTrash />
                                    Excluir
                                  </Flex>
                                </PopoverBody>
                              </PopoverContent>
                            </Popover>
                          </Td>
                          <Td color="white" borderColor="#1F2630">
                            {nome}
                          </Td>
                          <Td borderColor="#1F2630" color="white">
                            {email}
                          </Td>
                          <Td color="white" borderColor="#1F2630">
                            {machinesTotal}
                          </Td>
                          <Td color="white" borderColor="#1F2630">
                            {format(
                              new Date(dataInclusao),
                              "dd/MM/yyyy - kk:mm"
                            )}
                          </Td>
                          <Td color="white" borderColor="#1F2630">
                            {ultimoAcesso
                              ? format(
                                  new Date(ultimoAcesso),
                                  "dd/MM/yyyy - kk:mm"
                                )
                              : "--"}
                          </Td>
                          <Td color="white" borderColor="#1F2630">
                            {format(dtDateOnly, "dd/MM/yyyy")}
                          </Td>
                          <Td color="white" borderColor="#1F2630">
                            {ativo ? (
                              <div
                                style={{
                                  width: "15px",
                                  height: "15px",
                                  borderRadius: "50%",
                                  backgroundColor: "green",
                                }}
                              ></div>
                            ) : (
                              <div
                                style={{
                                  width: "15px",
                                  height: "15px",
                                  borderRadius: "50%",
                                  backgroundColor: "red",
                                }}
                              ></div>
                            )}
                          </Td>
                        </Tr>
                      );
                    }
                  )}
                </Tbody>
              </Table>
            </TableContainer>
          </TabPanel>
          <TabPanel>
            <TableContainer minH="500px">
              <Table size="sm">
                <Thead>
                  <Tr>
                    <Th borderColor="#1F2630"></Th>
                    <Th borderColor="#1F2630">Nome</Th>
                    <Th borderColor="#1F2630">Email</Th>
                    <Th borderColor="#1F2630">Total de máquinas</Th>
                    <Th borderColor="#1F2630">Data inclusão</Th>
                    <Th borderColor="#1F2630">Último acesso</Th>
                    <Th borderColor="#1F2630">Data vencimento</Th>
                    <Th borderColor="#1F2630">Ativo</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {clientesInadiplentes.map(
                    ({
                      id,
                      nome,
                      email,
                      dataInclusao,
                      dataVencimento,
                      ultimoAcesso,
                      Maquina,
                      ativo,
                      mercadoPagoToken,
                    }) => {
                      const machinesTotal = Maquina.length;
                      const dt = new Date(dataVencimento);
                      const dtDateOnly = new Date(
                        dt.valueOf() + dt.getTimezoneOffset() * 60 * 1000
                      );
                      return (
                        <Tr key={id}>
                          <Td borderColor="#1F2630">
                            <Popover placement="bottom-end">
                              <PopoverTrigger>
                                <Button
                                  style={{
                                    backgroundColor: "transparent",
                                    border: "none",
                                  }}
                                  _active={{ backgroundColor: "transparent" }}
                                  _hover={{ backgroundColor: "transparent" }}
                                >
                                  <FaBars color="white" />
                                </Button>
                              </PopoverTrigger>
                              <PopoverContent maxW="180px">
                                <PopoverArrow />
                                <PopoverBody>
                                  <Flex
                                    gap="10px"
                                    onClick={() =>
                                      handleClienteClick({
                                        id,
                                        nome,
                                        email,
                                        ativo,
                                        dataInclusao,
                                        dataVencimento,
                                        ultimoAcesso,
                                        mercadoPagoToken,
                                      })
                                    }
                                  >
                                    <FaEye />
                                    Ver mais
                                  </Flex>
                                  <Flex gap="10px" marginTop="10px" color="red">
                                    <FaTrash />
                                    Excluir
                                  </Flex>
                                </PopoverBody>
                              </PopoverContent>
                            </Popover>
                          </Td>
                          <Td color="white" borderColor="#1F2630">
                            {nome}
                          </Td>
                          <Td borderColor="#1F2630" color="white">
                            {email}
                          </Td>
                          <Td color="white" borderColor="#1F2630">
                            {machinesTotal}
                          </Td>
                          <Td color="white" borderColor="#1F2630">
                            {format(
                              new Date(dataInclusao),
                              "dd/MM/yyyy - kk:mm"
                            )}
                          </Td>
                          <Td color="white" borderColor="#1F2630">
                            {ultimoAcesso
                              ? format(
                                  new Date(ultimoAcesso),
                                  "dd/MM/yyyy - kk:mm"
                                )
                              : "--"}
                          </Td>
                          <Td color="white" borderColor="#1F2630">
                            {format(dtDateOnly, "dd/MM/yyyy")}
                          </Td>
                          <Td color="white" borderColor="#1F2630">
                            {ativo ? (
                              <div
                                style={{
                                  width: "15px",
                                  height: "15px",
                                  borderRadius: "50%",
                                  backgroundColor: "green",
                                }}
                              ></div>
                            ) : (
                              <div
                                style={{
                                  width: "15px",
                                  height: "15px",
                                  borderRadius: "50%",
                                  backgroundColor: "red",
                                }}
                              ></div>
                            )}
                          </Td>
                        </Tr>
                      );
                    }
                  )}
                </Tbody>
              </Table>
            </TableContainer>
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Flex>
  );
};

export default ClientesDashboard;
