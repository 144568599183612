import {
  Box,
  Button,
  Flex,
  Tab,
  Table,
  TableContainer,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import axios from "axios";
import { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../../contexts/AuthContext";
import { useNavigate } from "react-router-dom";
import { Badge, Tag } from "antd";
import { format } from "date-fns";
import { ArrowLeftOutlined, ArrowRightOutlined } from "@ant-design/icons";

export default function Vendas() {
  const [machines, setMachines] = useState([]);
  const [totalFornecedores, setTotalFornecedores] = useState([]);
  const [todosPagamentos, setTodosPagamentos] = useState([]);

  const [currentPageTodos, setCurrentPageTodos] = useState(1);
  const [currentPagePagas, setCurrentPagePagas] = useState(1);
  const [currentPageEstornados, setCurrentPageEstornados] = useState(1);
  const itemsPerPage = 10;

  // Calcular o índice dos itens a serem exibidos com base na página atual
  const startIndexTodos = (currentPageTodos - 1) * itemsPerPage;
  const startIndexPagos = (currentPagePagas - 1) * itemsPerPage;
  const startIndexEstornados = (currentPageEstornados - 1) * itemsPerPage;
  const endIndexTodos = startIndexTodos + itemsPerPage;
  const endIndexPagos = startIndexPagos + itemsPerPage;
  const endIndexEstornados = startIndexEstornados + itemsPerPage;

  // Funções para navegar entre as páginas

  const handlePreviousPage = (currentPage, setPage) => {
    if (currentPage > 1) setPage(currentPage - 1);
  };

  const handleNextPage = (currentPage, totalPages, setPage) => {
    if (currentPage < totalPages) setPage(currentPage + 1);
  };

  const { setDataUser, loading, authInfo, setNotiMessage } =
    useContext(AuthContext);
  const { dataUser } = authInfo;
  let navigate = useNavigate();
  const token = authInfo?.dataUser?.token;

  const dataData = () => {
    axios
      .get(`${process.env.REACT_APP_SERVIDOR}/maquinas`, {
        headers: {
          "x-access-token": token,
          "content-type": "application/json",
        },
      })
      .then((res) => {
        if (res.status === 200) {
          setTotalFornecedores(res.data);
        } else {
          throw new Error();
        }
      })
      .catch((err) => {
        if ([401, 403].includes(err.response.status)) {
          // setNotiMessage('A sua sessão expirou, para continuar faça login novamente.');
          setNotiMessage({
            type: "error",
            message:
              "A sua sessão expirou, para continuar faça login novamente.",
          });
          setDataUser(null);
        }
      });
  };

  useEffect(() => {
    dataData();
  }, []);

  useEffect(() => {
    const getRows = () => {
      const pagamentos = [];
      machines.forEach(({ nome, descricao, payments }) => {
        payments.pagamentos.forEach((pagamento) => {
          pagamentos.push({ cliente: nome, descricao, ...pagamento });
        });
      });

      setTodosPagamentos(pagamentos);
    };

    getRows();
  }, [machines]);

  useEffect(() => {
    const fetchMaquinas = async (maquinas) => {
      const promises = [];

      maquinas.forEach((maquina) => {
        const promise = axios
          .get(`${process.env.REACT_APP_SERVIDOR}/pagamentos/${maquina.id}`, {
            headers: {
              "x-access-token": token,
              "content-type": "application/json",
            },
          })
          .then((response) => {
            return {
              nome: maquina.nome,
              descricao: maquina.descricao,
              payments: response.data,
            };
          })
          .catch((error) => {
            console.error(
              `Erro ao buscar a máquina ${maquina.nome} do cliente ${maquina.nome}:`,
              error
            );
            return null; // Retorna null em caso de erro
          });

        promises.push(promise);
      });

      const resultados = await Promise.all(promises);

      return resultados;
    };

    fetchMaquinas(totalFornecedores).then((resultados) => {
      setMachines(resultados);
    });
  }, [token, totalFornecedores]);

  const pagamentosEstornados = todosPagamentos.filter(
    ({ estornado }) => estornado
  );

  const pagamentosNormal = todosPagamentos.filter(
    ({ estornado }) => !estornado
  );

  const totalPagesTodos = Math.ceil(todosPagamentos.length / itemsPerPage);
  const totalPagesPagas = Math.ceil(pagamentosNormal.length / itemsPerPage);
  const totalPagesEstornados = Math.ceil(
    pagamentosEstornados.length / itemsPerPage
  );

  const currentItemsPagos = pagamentosNormal.slice(
    startIndexPagos,
    endIndexPagos
  );
  const currentItemsEstornados = pagamentosEstornados.slice(
    currentPageEstornados,
    endIndexEstornados
  );
  const currentItemsTodos = todosPagamentos.slice(
    startIndexTodos,
    endIndexTodos
  );

  return (
    <Flex flexDirection="column" minH="100vh">
      <Text color="white" fontSize="24px">
        Vendas
      </Text>
      <Tabs>
        <TabList borderColor="#1F2630">
          <Tab
            color="white"
            _selected={{ borderColor: "white" }}
            fontSize="14px"
          >
            Todas{" "}
            <span
              style={{
                marginLeft: "10px",
                padding: "5px 10px",
                backgroundColor: "white",
                borderRadius: "8px",
                fontSize: "12px",
                color: "black",
              }}
            >
              {todosPagamentos.length}
            </span>
          </Tab>
          <Tab
            color="white"
            _selected={{ borderColor: "white" }}
            fontSize="14px"
          >
            Pagas{" "}
            <span
              style={{
                marginLeft: "10px",
                padding: "5px 10px",
                backgroundColor: "#21BD52",
                borderRadius: "8px",
                fontSize: "12px",
              }}
            >
              {pagamentosNormal.length}
            </span>
          </Tab>
          <Tab
            color="white"
            _selected={{ borderColor: "white" }}
            fontSize="14px"
          >
            Estornadas
            <span
              style={{
                marginLeft: "10px",
                padding: "5px 10px",
                backgroundColor: "#F45031",
                borderRadius: "8px",
                fontSize: "12px",
              }}
            >
              {pagamentosEstornados.length}
            </span>
          </Tab>
        </TabList>
        <TabPanels>
          <TabPanel>
            <TableContainer>
              <Table size="sm">
                <Thead>
                  <Tr>
                    <Th borderColor="#1F2630">Status</Th>
                    <Th borderColor="#1F2630">Cliente</Th>
                    <Th borderColor="#1F2630">Meio de pagamento</Th>
                    <Th borderColor="#1F2630">Preço</Th>
                    <Th borderColor="#1F2630">Data</Th>
                    <Th borderColor="#1F2630">Loja</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {currentItemsTodos.map(
                    ({ cliente, descricao, valor, estornado, tipo, data }) => (
                      <Tr>
                        <Td color="white" borderColor="#1F2630">
                          <Tag
                            color={estornado ? "#F45031" : "#21BD52"}
                            style={{ borderRadius: "8px" }}
                          >
                            {estornado ? "Estornada" : "Paga"}
                          </Tag>
                        </Td>
                        <Td borderColor="#1F2630" color="white">
                          {cliente}
                        </Td>
                        <Td color="white" borderColor="#1F2630">
                          {" "}
                          {tipo === "bank_transfer"
                            ? "PIX"
                            : tipo === "CASH"
                            ? "Espécie"
                            : tipo === "debit_card"
                            ? "Débito"
                            : tipo === "credit_card"
                            ? "Crédito"
                            : "-"}
                        </Td>
                        <Td color="white" borderColor="#1F2630">
                          {Intl.NumberFormat("pt-BR", {
                            style: "currency",
                            currency: "BRL",
                          }).format(valor)}
                        </Td>
                        <Td color="white" borderColor="#1F2630">
                          <span
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            {" "}
                            {format(new Date(data), "dd/MM/yyyy")}{" "}
                            <span style={{ marginLeft: "10px", color: "gray" }}>
                              {new Date(data).toLocaleTimeString("pt-BR", {
                                hour: "2-digit",
                                minute: "2-digit",
                              })}
                            </span>
                          </span>
                        </Td>
                        <Td color="white" borderColor="#1F2630">
                          {descricao}
                        </Td>
                      </Tr>
                    )
                  )}
                </Tbody>
              </Table>
            </TableContainer>
            <Flex justifyContent="flex-end" alignItems="center" gap="20px">
              <ArrowLeftOutlined
                onClick={() =>
                  handlePreviousPage(currentPageTodos, setCurrentPageTodos)
                }
                style={{ color: "white" }}
              />
              <Text marginTop="15px">{currentPageTodos}</Text>
              <ArrowRightOutlined
                onClick={() =>
                  handleNextPage(
                    currentPageTodos,
                    totalPagesTodos,
                    setCurrentPageTodos
                  )
                }
                style={{ color: "white" }}
              />
            </Flex>
          </TabPanel>
          <TabPanel>
            <TableContainer>
              <Table size="sm">
                <Thead>
                  <Tr>
                    <Th borderColor="#1F2630">Status</Th>
                    <Th borderColor="#1F2630">Cliente</Th>
                    <Th borderColor="#1F2630">Meio de pagamento</Th>
                    <Th borderColor="#1F2630">Preço</Th>
                    <Th borderColor="#1F2630">Data</Th>
                    <Th borderColor="#1F2630">Loja</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {currentItemsPagos.map(
                    ({ cliente, descricao, valor, estornado, tipo, data }) => (
                      <Tr>
                        <Td color="white" borderColor="#1F2630">
                          <Tag
                            color={estornado ? "#F45031" : "#21BD52"}
                            style={{ borderRadius: "8px" }}
                          >
                            {estornado ? "Estornada" : "Paga"}
                          </Tag>
                        </Td>
                        <Td borderColor="#1F2630" color="white">
                          {cliente}
                        </Td>
                        <Td color="white" borderColor="#1F2630">
                          {" "}
                          {tipo === "bank_transfer"
                            ? "PIX"
                            : tipo === "CASH"
                            ? "Espécie"
                            : tipo === "debit_card"
                            ? "Débito"
                            : tipo === "credit_card"
                            ? "Crédito"
                            : "-"}
                        </Td>
                        <Td color="white" borderColor="#1F2630">
                          {Intl.NumberFormat("pt-BR", {
                            style: "currency",
                            currency: "BRL",
                          }).format(valor)}
                        </Td>
                        <Td
                          color="white"
                          borderColor="#1F2630"
                          flexDirection="column"
                          alignItems="center"
                        >
                          {format(new Date(data), "dd/MM/yyyy")}
                          <Text>
                            {new Date(data).toLocaleTimeString("pt-BR", {
                              hour: "2-digit",
                              minute: "2-digit",
                            })}
                          </Text>
                        </Td>
                        <Td color="white" borderColor="#1F2630">
                          {descricao}
                        </Td>
                      </Tr>
                    )
                  )}
                </Tbody>
              </Table>
            </TableContainer>
            <Flex justifyContent="flex-end" alignItems="center" gap="20px">
              <ArrowLeftOutlined
                onClick={() =>
                  handlePreviousPage(currentPagePagas, setCurrentPagePagas)
                }
                style={{ color: "white" }}
              />
              <Text marginTop="15px">{currentPagePagas}</Text>
              <ArrowRightOutlined
                onClick={() =>
                  handleNextPage(
                    currentPagePagas,
                    totalPagesPagas,
                    setCurrentPagePagas
                  )
                }
                style={{ color: "white" }}
              />
            </Flex>
          </TabPanel>
          <TabPanel>
            <TableContainer>
              <Table size="sm">
                <Thead>
                  <Tr>
                    <Th borderColor="#1F2630">Status</Th>
                    <Th borderColor="#1F2630">Cliente</Th>
                    <Th borderColor="#1F2630">Meio de pagamento</Th>
                    <Th borderColor="#1F2630">Preço</Th>
                    <Th borderColor="#1F2630">Data</Th>
                    <Th borderColor="#1F2630">Loja</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {currentItemsEstornados.map(
                    ({ cliente, descricao, valor, estornado, tipo, data }) => (
                      <Tr>
                        <Td color="white" borderColor="#1F2630">
                          <Tag
                            color={estornado ? "#F45031" : "#21BD52"}
                            style={{ borderRadius: "8px" }}
                          >
                            {estornado ? "Estornada" : "Paga"}
                          </Tag>
                        </Td>
                        <Td borderColor="#1F2630" color="white">
                          {cliente}
                        </Td>
                        <Td color="white" borderColor="#1F2630">
                          {" "}
                          {tipo === "bank_transfer"
                            ? "PIX"
                            : tipo === "CASH"
                            ? "Espécie"
                            : tipo === "debit_card"
                            ? "Débito"
                            : tipo === "credit_card"
                            ? "Crédito"
                            : "-"}
                        </Td>
                        <Td color="white" borderColor="#1F2630">
                          {Intl.NumberFormat("pt-BR", {
                            style: "currency",
                            currency: "BRL",
                          }).format(valor)}
                        </Td>
                        <Td
                          color="white"
                          borderColor="#1F2630"
                          flexDirection="column"
                          alignItems="center"
                        >
                          {format(new Date(data), "dd/MM/yyyy")}
                          <Text>
                            {new Date(data).toLocaleTimeString("pt-BR", {
                              hour: "2-digit",
                              minute: "2-digit",
                            })}
                          </Text>
                        </Td>
                        <Td color="white" borderColor="#1F2630">
                          {descricao}
                        </Td>
                      </Tr>
                    )
                  )}
                </Tbody>
              </Table>
            </TableContainer>
            <Flex justifyContent="flex-end" alignItems="center" gap="20px">
              <ArrowLeftOutlined
                onClick={() =>
                  handlePreviousPage(
                    currentPageEstornados,
                    setCurrentPageEstornados
                  )
                }
                style={{ color: "white" }}
              />
              <Text marginTop="15px">{currentPageEstornados}</Text>
              <ArrowRightOutlined
                onClick={() =>
                  handleNextPage(
                    currentPageEstornados,
                    totalPagesEstornados,
                    setCurrentPageEstornados
                  )
                }
                style={{ color: "white" }}
              />
            </Flex>
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Flex>
  );
}
