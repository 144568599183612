import React, { useContext, useEffect, useState } from "react";
import "./ClienteMaquinas.css";
import { Button, Col, Row } from "antd";
import axios from "axios";
import * as links from "../../../../utils/links";
import { AuthContext } from "../../../../contexts/AuthContext";
import { Link, useNavigate, useLocation } from "react-router-dom";
import LoadingAction from "../../../../themes/LoadingAction/LoadingAction";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowsRotate,
  faCheckCircle,
  faXmarkCircle,
} from "@fortawesome/free-solid-svg-icons";
import { AiOutlineEdit, AiOutlinePlusCircle } from "react-icons/ai";
import { useParams } from "react-router-dom";
import {
  Flex,
  FormControl,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalOverlay,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Tab,
  Table,
  TableContainer,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useDisclosure,
  useMediaQuery,
} from "@chakra-ui/react";
import {
  FaBars,
  FaBitcoin,
  FaEdit,
  FaEye,
  FaTrash,
  FaWifi,
} from "react-icons/fa";

const ModalModuleEdit = ({ isOpen, onClose, moduleProps = {} }) => {
  const [moduleInfo, setModuleInfo] = useState(moduleProps);

  const { authInfo, setNotiMessage } = useContext(AuthContext);

  const token = authInfo?.dataUser?.token;

  const onSave = async () => {
    await axios
      .put(
        `${process.env.REACT_APP_SERVIDOR}/maquina`,
        {
          id: moduleInfo.id,
          nome: moduleInfo.nome,
          descricao: moduleInfo.descricao,
          estoque: Number(moduleInfo.estoque),
          store_id: String(moduleInfo.store_id),
          valorDoPulso: moduleInfo.pulso,
        },
        {
          headers: {
            "x-access-token": token,
            "content-type": "application/json",
          },
        }
      )
      .then((res) => {
        onClose();
        window.location.reload();
      });
  };

  useEffect(() => {
    setModuleInfo(moduleProps);
  }, [moduleProps]);
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent backgroundColor="#1a202c">
        <ModalCloseButton color="white" />
        <ModalBody style={{ marginTop: "50px" }}>
          <form>
            <FormControl>
              <FormLabel color="white">Nome</FormLabel>
              <Input
                type="text"
                value={moduleInfo.nome}
                color="white"
                onChange={({ target }) =>
                  setModuleInfo((prev) => ({
                    ...prev,
                    nome: target.value,
                  }))
                }
              />
            </FormControl>
            <FormControl marginTop="20px">
              <FormLabel color="white">Loja</FormLabel>
              <Input
                type="text"
                value={moduleInfo.descricao}
                color="white"
                onChange={({ target }) =>
                  setModuleInfo((prev) => ({
                    ...prev,
                    descricao: target.value,
                  }))
                }
              />
            </FormControl>
            <FormControl marginTop="20px">
              <FormLabel color="white">Estoque</FormLabel>
              <Input
                type="text"
                value={moduleInfo.estoque}
                color="white"
                onChange={({ target }) =>
                  setModuleInfo((prev) => ({
                    ...prev,
                    estoque: target.value,
                  }))
                }
              />
            </FormControl>
            <FormControl marginTop="20px">
              <FormLabel color="white">Pulso</FormLabel>
              <Input
                type="text"
                value={moduleInfo.pulso}
                color="white"
                onChange={({ target }) =>
                  setModuleInfo((prev) => ({
                    ...prev,
                    pulso: target.value,
                  }))
                }
              />
            </FormControl>
            <FormControl marginTop="20px">
              <FormLabel color="white">Store ID</FormLabel>
              <Input
                type="text"
                value={moduleInfo.store_id}
                color="white"
                onChange={({ target }) =>
                  setModuleInfo((prev) => ({
                    ...prev,
                    store_id: target.value,
                  }))
                }
              />
            </FormControl>
          </form>
        </ModalBody>

        <ModalFooter>
          <Button variant="ghost" onClick={onClose} color="white">
            Cancelar
          </Button>
          <Button
            colorScheme="blue"
            mr={3}
            onClick={onSave}
            style={{ marginLeft: "20px" }}
          >
            Salvar
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

const DeleteModal = ({ isOpen, onClose, id }) => {
  const { setDataUser, loading, authInfo, setNotiMessage } =
    useContext(AuthContext);
  const token = authInfo?.dataUser?.token;

  const deleteHandler = () => {
    let config = {
      method: "delete",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_SERVIDOR}/maquina`,
      headers: {
        "x-access-token": token,
        "Content-Type": "application/json",
      },
      data: { id },
    };

    axios
      .request(config)
      .then((res) => {
        setNotiMessage({
          type: "success",
          message: "Deleted Successfully.",
        });
        onClose();
        window.location.reload();
      })
      .catch((err) => {
        if ([401, 403].includes(err.response.status)) {
          setNotiMessage({
            type: "error",
            message: "Delete Failed",
          });
          setDataUser(null);
        }
      });
  };
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent backgroundColor="#1a202c">
        <ModalCloseButton color="white" />
        <ModalBody style={{ marginTop: "50px", textAlign: "center" }}>
          <Text fontSize="20px" color="white">
            Deseja excluir essa máquina?
          </Text>
        </ModalBody>

        <ModalFooter>
          <Button variant="ghost" onClick={onClose} color="white">
            Cancelar
          </Button>
          <Button
            colorScheme="blue"
            mr={3}
            onClick={deleteHandler}
            style={{ marginLeft: "20px" }}
          >
            Excluir
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

const RemoteCreditModal = ({ isOpen, onClose, id }) => {
  const { setDataUser, loading, authInfo, setNotiMessage } =
    useContext(AuthContext);
  const token = authInfo?.dataUser?.token;

  const [value, setValue] = useState(0);

  const addCredit = () => {
    axios
      .post(
        `${process.env.REACT_APP_SERVIDOR}/credito-remoto`,
        {
          id: id,
          valor: value,
        },
        {
          headers: {
            "x-access-token": token,
            "content-type": "application/json",
          },
        }
      )
      .then((res) => {
        setNotiMessage({
          type: "success",
          message: `${res?.data?.retorno}`,
        });
        onClose(); // Fecha o modal após o sucesso
      })
      .catch((err) => {
        if ([401, 403].includes(err.response.status)) {
          setNotiMessage({
            type: "error",
            message:
              "A sua sessão expirou, para continuar faça login novamente.",
          });
          setDataUser(null);
        } else {
          setNotiMessage({
            type: "error",
            message: `Erro, algo deu errado ${err.response?.data?.msg}`,
          });
        }
      });
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent backgroundColor="#1a202c">
        <ModalCloseButton color="white" />
        <ModalBody style={{ marginTop: "50px", textAlign: "center" }}>
          <form>
            <FormControl>
              <FormLabel color="white">Valor</FormLabel>
              <Input
                type="number"
                value={value}
                color="white"
                onChange={({ target }) => setValue(target.value)}
              />
            </FormControl>
          </form>
        </ModalBody>

        <ModalFooter>
          <Button variant="ghost" onClick={onClose} color="white">
            Cancelar
          </Button>
          <Button
            colorScheme="blue"
            mr={3}
            onClick={addCredit}
            style={{ marginLeft: "20px" }}
          >
            Adicionar
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

const ClienteMaquinas = (props) => {
  const { setDataUser, authInfo, setNotiMessage } = useContext(AuthContext);
  const { dataUser } = authInfo;

  let navigate = useNavigate();
  const location = useLocation();

  const clienteInfo = location.state;

  const token = authInfo?.dataUser?.token;

  const { id } = useParams();

  const [totalClienteMaquinas, setTotalClienteMaquinas] = useState([]);
  const [moduleProps, setModuleProps] = useState({});
  const [selectedId, setSelectedId] = useState(undefined);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    dataData();

    const intervalId = setInterval(() => {
      dataData();
    }, 60000);

    return () => clearInterval(intervalId);
  }, []);

  const [isLargerThan800] = useMediaQuery("(min-width: 800px)");

  const dataData = () => {
    setIsLoading(true);
    axios
      .get(`${process.env.REACT_APP_SERVIDOR}/maquinas-adm?id=${id}`, {
        headers: {
          "x-access-token": token,
          "content-type": "application/json",
        },
      })
      .then((res) => {
        if (res.status === 200) {
          setIsLoading(false);
          setTotalClienteMaquinas(res.data);
        } else {
          throw new Error();
        }
      })
      .catch((err) => {
        setIsLoading(false);
        if ([401, 403].includes(err.response.status)) {
          setNotiMessage({
            type: "error",
            message:
              "A sua sessão expirou, para continuar faça login novamente.",
          });
          setDataUser(null);
        }
      });
  };

  const availableModules = totalClienteMaquinas.filter(
    ({ status }) => status !== "OFFLINE"
  );
  const unavailableModules = totalClienteMaquinas.filter(
    ({ status }) => status === "OFFLINE"
  );

  const { onOpen, onClose, isOpen } = useDisclosure();
  const {
    onOpen: onOpenDeleteModal,
    onClose: onCloseDeleteModal,
    isOpen: isOpenDeleteModal,
  } = useDisclosure();
  const {
    onOpen: onOpenRemoteCreditModal,
    onClose: onCloseRemoteCreditModal,
    isOpen: isOpenRemoteCreditModal,
  } = useDisclosure();

  return (
    <div
      className="Cliente_Maquinas_container"
      style={{ minHeight: "100vh", width: "100%" }}
    >
      {isLoading && <LoadingAction />}
      <div className="WarningMsgSpan">
        <span>{dataUser.warningMsg}</span>
      </div>
      <div className="AddCliente_header">
        <div className="AddCliente_header_title" style={{ color: "white" }}>
          Dispositivos do cliente:
        </div>
        <Button
          className="AddCliente_header_back"
          to={links.DASHBOARD_CLIENTES}
          onClick={() => navigate(links.DASHBOARD_CLIENTES)}
        >
          VOLTAR
        </Button>
      </div>
      <div className="Cliente_Maquinas_header">
        <div style={{ color: "white", marginLeft: "15px", fontSize: "26px" }}>
          {clienteInfo.nome}
        </div>
      </div>
      <Flex
        flexDirection={isLargerThan800 ? "row" : "column"}
        gap={!isLargerThan800 && "10px"}
      >
        <Button
          style={{
            margin: isLargerThan800 && "0 15px",
            borderRadius: "8px",
            width: !isLargerThan800 && "200px",
          }}
        >
          <FontAwesomeIcon
            icon={faArrowsRotate}
            style={{ marginRight: "5px" }}
          />
          Atualizar
        </Button>

        <Button
          className="Cliente_Maquinas_addbtn"
          onClick={() =>
            navigate(`${links.ADD_CLIENTES_MAQUINA_ADM}/${clienteInfo.id}`, {
              state: location.state,
            })
          }
          style={{ borderRadius: "8px", width: !isLargerThan800 && "200px" }}
        >
          <AiOutlinePlusCircle />
          <span>Addcionar Máquina</span>
        </Button>

        <div style={{ margin: isLargerThan800 && "0 15px" }}>
          <Button
            className="Cliente_Maquinas_addbtn"
            onClick={() =>
              navigate(`${links.EDITAR_CLIENTES}/${clienteInfo.id}`, {
                state: clienteInfo,
              })
            }
            style={{
              borderRadius: "8px",
              width: !isLargerThan800 && "200px",
              textAlign: "center",
              justifyContent: "center",
            }}
          >
            <AiOutlineEdit />
            <span>Editar Cliente</span>
          </Button>
        </div>
      </Flex>
      <Flex width="100%" marginTop="20px">
        <Tabs width="100%">
          <TabList borderColor="#1F2630">
            <Tab
              color="white"
              _selected={{ borderColor: "white" }}
              fontSize="14px"
            >
              Todos{" "}
              <span
                style={{
                  marginLeft: "10px",
                  padding: "5px 10px",
                  backgroundColor: "#01B0D1",
                  borderRadius: "8px",
                  fontSize: "12px",
                }}
              >
                {totalClienteMaquinas.length}
              </span>
            </Tab>
            <Tab
              color="white"
              _selected={{ borderColor: "white" }}
              fontSize="14px"
            >
              Online{" "}
              <span
                style={{
                  marginLeft: "10px",
                  padding: "5px 10px",
                  backgroundColor: "#21BD52",
                  borderRadius: "8px",
                  fontSize: "12px",
                }}
              >
                {availableModules.length}
              </span>
            </Tab>
            <Tab
              color="white"
              _selected={{ borderColor: "white" }}
              fontSize="14px"
            >
              Offline
              <span
                style={{
                  marginLeft: "10px",
                  padding: "5px 10px",
                  backgroundColor: "#F45031",
                  borderRadius: "8px",
                  fontSize: "12px",
                }}
              >
                {unavailableModules.length}
              </span>
            </Tab>
          </TabList>

          <TabPanels>
            <TabPanel>
              <TableContainer minH="500px">
                <Table size="sm">
                  <Thead>
                    <Tr>
                      <Th borderColor="#1F2630"></Th>
                      <Th borderColor="#1F2630">Nome</Th>
                      <Th borderColor="#1F2630">Conexão</Th>
                      <Th borderColor="#1F2630">Loja</Th>
                      <Th borderColor="#1F2630">Estoque</Th>
                      <Th borderColor="#1F2630">Último Pagamento</Th>
                      <Th borderColor="#1F2630">Status</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {totalClienteMaquinas.map(
                      ({
                        id,
                        nome,
                        descricao,
                        estoque,
                        ultimoPagamentoRecebido,
                        status,
                        store_id,
                        pulso,
                      }) => (
                        <Tr>
                          <Td borderColor="#1F2630">
                            <Popover placement="bottom-end">
                              <PopoverTrigger>
                                <Button
                                  backgroundColor="transparent"
                                  style={{
                                    backgroundColor: "transparent",
                                    border: "none",
                                  }}
                                  _active={{ backgroundColor: "transparent" }}
                                  _hover={{ backgroundColor: "transparent" }}
                                >
                                  <FaBars color="white" />
                                </Button>
                              </PopoverTrigger>
                              <PopoverContent maxW="180px">
                                <PopoverArrow />
                                <PopoverBody>
                                  <Flex
                                    gap="10px"
                                    onClick={() => {
                                      setModuleProps({
                                        id,
                                        nome,
                                        descricao,
                                        estoque,
                                        store_id,
                                        pulso,
                                      });
                                      onOpen();
                                    }}
                                  >
                                    <FaEdit />
                                    Editar
                                  </Flex>
                                  <Flex
                                    gap="10px"
                                    marginTop="10px"
                                    onClick={() => {
                                      setSelectedId(id);
                                      onOpenRemoteCreditModal();
                                    }}
                                  >
                                    <FaBitcoin />
                                    Crédito Remoto
                                  </Flex>
                                  <Flex
                                    gap="10px"
                                    marginTop="10px"
                                    onClick={() =>
                                      navigate(
                                        `${links.CLIENTES_MAQUINAS_FORNECEDOR_SEARCH}/${id}`,
                                        {
                                          state: {
                                            clienteInfo,
                                            maquinaInfos: {
                                              id,
                                              nome,
                                              descricao,
                                              estoque,
                                              ultimoPagamentoRecebido,
                                              status,
                                              store_id,
                                              pulso,
                                            },
                                          },
                                        }
                                      )
                                    }
                                  >
                                    <FaEye />
                                    Ver mais
                                  </Flex>
                                  <Flex
                                    gap="10px"
                                    marginTop="10px"
                                    color="red"
                                    onClick={() => {
                                      setSelectedId(id);
                                      onOpenDeleteModal();
                                    }}
                                  >
                                    <FaTrash />
                                    Excluir
                                  </Flex>
                                </PopoverBody>
                              </PopoverContent>
                            </Popover>
                          </Td>
                          <Td color="white" borderColor="#1F2630">
                            {nome}
                          </Td>
                          <Td borderColor="#1F2630">
                            <FaWifi
                              color={status === "OFFLINE" ? "red" : "green"}
                            />
                          </Td>
                          <Td color="white" borderColor="#1F2630">
                            {descricao}
                          </Td>
                          <Td color="white" borderColor="#1F2630">
                            {estoque}
                          </Td>
                          <Td color="white" borderColor="#1F2630">
                            {ultimoPagamentoRecebido}
                          </Td>
                          <Td color="white" borderColor="#1F2630">
                            {status}
                          </Td>
                        </Tr>
                      )
                    )}
                  </Tbody>
                </Table>
              </TableContainer>
            </TabPanel>
            <TabPanel>
              <TableContainer minH="500px">
                <Table size="sm">
                  <Thead>
                    <Tr>
                      <Th borderColor="#1F2630"></Th>
                      <Th borderColor="#1F2630">Nome</Th>
                      <Th borderColor="#1F2630">Conexão</Th>
                      <Th borderColor="#1F2630">Loja</Th>
                      <Th borderColor="#1F2630">Estoque</Th>
                      <Th borderColor="#1F2630">Último Pagamento</Th>
                      <Th borderColor="#1F2630">Status</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {availableModules.map(
                      ({
                        id,
                        nome,
                        descricao,
                        estoque,
                        ultimoPagamentoRecebido,
                        status,
                        store_id,
                        pulso,
                      }) => (
                        <Tr>
                          <Td borderColor="#1F2630">
                            <Popover placement="bottom-end">
                              <PopoverTrigger>
                                <Button
                                  backgroundColor="transparent"
                                  style={{
                                    backgroundColor: "transparent",
                                    border: "none",
                                  }}
                                  _active={{ backgroundColor: "transparent" }}
                                  _hover={{ backgroundColor: "transparent" }}
                                >
                                  <FaBars color="white" />
                                </Button>
                              </PopoverTrigger>
                              <PopoverContent maxW="180px">
                                <PopoverArrow />
                                <PopoverBody>
                                  <Flex
                                    gap="10px"
                                    onClick={() => {
                                      setModuleProps({
                                        id,
                                        nome,
                                        descricao,
                                        estoque,
                                        store_id,
                                        pulso,
                                      });
                                      onOpen();
                                    }}
                                  >
                                    <FaEdit />
                                    Editar
                                  </Flex>
                                  <Flex
                                    gap="10px"
                                    marginTop="10px"
                                    onClick={() => {
                                      setSelectedId(id);
                                      onOpenRemoteCreditModal();
                                    }}
                                  >
                                    <FaBitcoin />
                                    Crédito Remoto
                                  </Flex>
                                  <Flex
                                    gap="10px"
                                    marginTop="10px"
                                    onClick={() =>
                                      navigate(
                                        `${links.CLIENTES_MAQUINAS_FORNECEDOR_SEARCH}/${id}`,
                                        {
                                          state: {
                                            clienteInfo,
                                            maquinaInfos: {
                                              id,
                                              nome,
                                              descricao,
                                              estoque,
                                              ultimoPagamentoRecebido,
                                              status,
                                              store_id,
                                              pulso,
                                            },
                                          },
                                        }
                                      )
                                    }
                                  >
                                    <FaEye />
                                    Ver mais
                                  </Flex>
                                  <Flex
                                    gap="10px"
                                    marginTop="10px"
                                    color="red"
                                    onClick={() => {
                                      setSelectedId(id);
                                      onOpenDeleteModal();
                                    }}
                                  >
                                    <FaTrash />
                                    Excluir
                                  </Flex>
                                </PopoverBody>
                              </PopoverContent>
                            </Popover>
                          </Td>
                          <Td color="white" borderColor="#1F2630">
                            {nome}
                          </Td>
                          <Td borderColor="#1F2630">
                            <FaWifi
                              color={status === "OFFLINE" ? "red" : "green"}
                            />
                          </Td>
                          <Td color="white" borderColor="#1F2630">
                            {descricao}
                          </Td>
                          <Td color="white" borderColor="#1F2630">
                            {estoque}
                          </Td>
                          <Td color="white" borderColor="#1F2630">
                            {ultimoPagamentoRecebido}
                          </Td>
                          <Td color="white" borderColor="#1F2630">
                            {status}
                          </Td>
                        </Tr>
                      )
                    )}
                  </Tbody>
                </Table>
              </TableContainer>
            </TabPanel>
            <TabPanel>
              <TableContainer minH="500px">
                <Table size="sm">
                  <Thead>
                    <Tr>
                      <Th borderColor="#1F2630"></Th>
                      <Th borderColor="#1F2630">Nome</Th>
                      <Th borderColor="#1F2630">Conexão</Th>
                      <Th borderColor="#1F2630">Loja</Th>
                      <Th borderColor="#1F2630">Estoque</Th>
                      <Th borderColor="#1F2630">Último Pagamento</Th>
                      <Th borderColor="#1F2630">Status</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {unavailableModules.map(
                      ({
                        id,
                        nome,
                        descricao,
                        estoque,
                        ultimoPagamentoRecebido,
                        status,
                        store_id,
                        pulso,
                      }) => (
                        <Tr>
                          <Td borderColor="#1F2630">
                            <Popover placement="bottom-end">
                              <PopoverTrigger>
                                <Button
                                  backgroundColor="transparent"
                                  style={{
                                    backgroundColor: "transparent",
                                    border: "none",
                                  }}
                                  _active={{ backgroundColor: "transparent" }}
                                  _hover={{ backgroundColor: "transparent" }}
                                >
                                  <FaBars color="white" />
                                </Button>
                              </PopoverTrigger>
                              <PopoverContent maxW="180px">
                                <PopoverArrow />
                                <PopoverBody>
                                  <Flex
                                    gap="10px"
                                    onClick={() => {
                                      setModuleProps({
                                        id,
                                        nome,
                                        descricao,
                                        estoque,
                                        store_id,
                                        pulso,
                                      });
                                      onOpen();
                                    }}
                                  >
                                    <FaEdit />
                                    Editar
                                  </Flex>
                                  <Flex
                                    gap="10px"
                                    marginTop="10px"
                                    onClick={() => {
                                      setSelectedId(id);
                                      onOpenRemoteCreditModal();
                                    }}
                                  >
                                    <FaBitcoin />
                                    Crédito Remoto
                                  </Flex>
                                  <Flex
                                    gap="10px"
                                    marginTop="10px"
                                    onClick={() =>
                                      navigate(
                                        `${links.CLIENTES_MAQUINAS_FORNECEDOR_SEARCH}/${id}`,
                                        {
                                          state: {
                                            clienteInfo,
                                            maquinaInfos: {
                                              id,
                                              nome,
                                              descricao,
                                              estoque,
                                              ultimoPagamentoRecebido,
                                              status,
                                              store_id,
                                              pulso,
                                            },
                                          },
                                        }
                                      )
                                    }
                                  >
                                    <FaEye />
                                    Ver mais
                                  </Flex>
                                  <Flex
                                    gap="10px"
                                    marginTop="10px"
                                    color="red"
                                    onClick={() => {
                                      setSelectedId(id);
                                      onOpenDeleteModal();
                                    }}
                                  >
                                    <FaTrash />
                                    Excluir
                                  </Flex>
                                </PopoverBody>
                              </PopoverContent>
                            </Popover>
                          </Td>
                          <Td color="white" borderColor="#1F2630">
                            {nome}
                          </Td>
                          <Td borderColor="#1F2630">
                            <FaWifi
                              color={status === "OFFLINE" ? "red" : "green"}
                            />
                          </Td>
                          <Td color="white" borderColor="#1F2630">
                            {descricao}
                          </Td>
                          <Td color="white" borderColor="#1F2630">
                            {estoque}
                          </Td>
                          <Td color="white" borderColor="#1F2630">
                            {ultimoPagamentoRecebido}
                          </Td>
                          <Td color="white" borderColor="#1F2630">
                            {status}
                          </Td>
                        </Tr>
                      )
                    )}
                  </Tbody>
                </Table>
              </TableContainer>
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Flex>
      <ModalModuleEdit
        isOpen={isOpen}
        onClose={onClose}
        moduleProps={moduleProps}
      />
      <DeleteModal
        isOpen={isOpenDeleteModal}
        onClose={onCloseDeleteModal}
        id={selectedId}
      />
      <RemoteCreditModal
        isOpen={isOpenRemoteCreditModal}
        onClose={onCloseRemoteCreditModal}
        id={selectedId}
      />
    </div>
  );
};

export default ClienteMaquinas;
