import React, { useContext, useEffect, useRef, useState } from "react";
import "./RelatorioAdmin.css";
import { Col, Row, Button, Typography } from "antd";
import axios from "axios";
import { AuthContext } from "../../../../contexts/AuthContext";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import LoadingAction from "../../../../themes/LoadingAction/LoadingAction";
import os_icon from "../../../../assets/images/OS.png";
import money_bag from "../../../../assets/images/money_bag.png";
import hand_money from "../../../../assets/images/hand_money.png";
import card from "../../../../assets/images/card.png";
import pix_icon from "../../../../assets/images/pix.png";
import credito_icon from "../../../../assets/images/credito.png";
import debito_icon from "../../../../assets/images/debito.png";
import especie_icon from "../../../../assets/images/especie.png";
import * as links from "../../../../utils/links";
import moment from "moment";
import {
  Flex,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import html2pdf from "html2pdf.js";
import logoPix from "../../../../assets/images/logoPix.png";

const DonutChart = ({ data }) => {
  const options = {
    chart: {
      type: "pie",
      width: 250,
      height: 300,
      margin: [0, 0, 40, 0], // Ajuste das margens (topo, direita, fundo, esquerda)
      spacing: [0, 0, 0, 0],
      backgroundColor: "transparent",
    },
    title: {
      text: "",
    },
    credits: {
      enabled: false,
    },
    legend: {
      itemStyle: {
        color: "white", // Define a cor dos itens da legenda como branco
        fontWeight: "bold",
      },
    },
    plotOptions: {
      pie: {
        innerSize: "70%",
        allowPointSelect: true,
        cursor: "pointer",
        dataLabels: {
          enabled: true,
          format: "<b>{point.percentage:.1f}%</b>",
          distance: -20,
          style: {
            color: "black",
            fontWeight: "bold",
            fontSize: "14px",
          },
        },
        borderWidth: 0, // Remove o contorno entre as fatias
      },
    },
    series: [
      {
        name: "Valor",
        data,
        showInLegend: true,
      },
    ],
  };

  return <HighchartsReact highcharts={Highcharts} options={options} />;
};

const RelatorioAdmin = (props) => {
  const { authInfo, setNotiMessage } = useContext(AuthContext);
  const { dataUser } = authInfo;
  let navigate = useNavigate();
  const location = useLocation();

  const { maquinaInfos, dataInicio, dataFim, totalVendas } = location.state;

  const { id } = useParams();

  const contentRef = useRef();

  const token = authInfo?.dataUser?.token;

  const [estornos, setEstornos] = useState({ SOMA: "0" });
  const [pagamentos, setPagamentos] = useState({
    PIX: "0",
    ESPECIE: "0",
    CREDITO: "0",
    DEBITO: "0",
  });
  const [taxas, setTaxas] = useState({
    PIX: "0",
    CREDITO: "0",
    DEBITO: "0",
  });
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    dataData();

    const intervalId = setInterval(() => {
      dataData();
    }, 60000);

    return () => clearInterval(intervalId);
  }, []);

  const handleGeneratePdf = () => {
    const element = contentRef.current;

    // Configuração e geração do PDF
    const options = {
      margin: 0.5,
      filename: "relatorio.pdf",
      image: { type: "jpeg", quality: 0.98 },
      html2canvas: { scale: 2 },
      jsPDF: { unit: "in", format: "a4", orientation: "portrait" },
    };

    html2pdf().set(options).from(element).save();
  };

  const totalPagamentos =
    Number(pagamentos?.ESPECIE) +
    Number(pagamentos?.PIX) +
    Number(pagamentos.CREDITO) +
    Number(pagamentos.DEBITO);

  const ContentPdf = () => {
    return (
      <div
        ref={contentRef}
        style={{
          padding: "20px",
          backgroundColor: "white",
        }}
      >
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            marginBottom: "20px",
          }}
        >
          {" "}
          <img
            src={logoPix}
            alt="logo"
            style={{ width: "150px", height: "150px" }}
          />
        </div>

        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            marginBottom: "20px",
          }}
        >
          <Typography style={{ fontSize: "22px", fontWeight: 700 }}>
            Total de vendas - de{" "}
            {new Intl.DateTimeFormat("pt-BR").format(new Date(dataInicio))} até{" "}
            {new Intl.DateTimeFormat("pt-BR").format(new Date(dataFim))}
          </Typography>
        </div>
        <table
          style={{
            width: "100%",
            border: "1px solid gray",
            borderCollapse: "collapse",
          }}
        >
          <tr style={{ borderBottom: "1px solid gray" }}>
            <th style={{ border: "1px solid gray", padding: "8px" }}>
              Máquina
            </th>
            <th style={{ border: "1px solid gray", padding: "8px" }}>Id</th>
            <th style={{ border: "1px solid gray", padding: "8px" }}>
              Total geral
            </th>
          </tr>
          <tr style={{ borderBottom: "1px solid gray" }}>
            <td style={{ border: "1px solid gray", padding: "8px" }}>
              1. {maquinaInfos.nome}
            </td>
            <td style={{ border: "1px solid gray", padding: "8px" }}>
              {maquinaInfos.id}
            </td>
            <td
              style={{
                display: "flex",
                flexDirection: "column",
                border: "1px solid gray",
                padding: "8px",
              }}
            >
              {new Intl.NumberFormat("pt-BR", {
                style: "currency",
                currency: "BRL",
              }).format(totalPagamentos)}
              <Typography>{totalVendas} vendas</Typography>
            </td>
          </tr>
          <tr style={{ borderBottom: "1px solid gray" }}>
            <td style={{ border: "1px solid gray", padding: "8px" }}></td>
            <td
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-end",
                paddingRight: "20px",
                border: "1px solid gray",
                padding: "8px",
              }}
            >
              <Typography style={{ fontWeight: "700" }}>Total</Typography>
              <Typography style={{ fontWeight: "700" }}>
                Pix: {"  "}
                {new Intl.NumberFormat("pt-BR", {
                  style: "currency",
                  currency: "BRL",
                }).format(pagamentos.PIX)}
              </Typography>
              <Typography style={{ fontWeight: "700" }}>
                Crédito: {"  "}
                {new Intl.NumberFormat("pt-BR", {
                  style: "currency",
                  currency: "BRL",
                }).format(pagamentos.CREDITO)}
              </Typography>
              <Typography style={{ fontWeight: "700" }}>
                Débito: {"  "}
                {new Intl.NumberFormat("pt-BR", {
                  style: "currency",
                  currency: "BRL",
                }).format(pagamentos.DEBITO)}
              </Typography>
              <Typography style={{ fontWeight: "700" }}>
                Espécie: {"  "}
                {new Intl.NumberFormat("pt-BR", {
                  style: "currency",
                  currency: "BRL",
                }).format(pagamentos.ESPECIE)}
              </Typography>
            </td>
            <td style={{ border: "1px solid gray", padding: "8px" }}>
              {new Intl.NumberFormat("pt-BR", {
                style: "currency",
                currency: "BRL",
              }).format(totalPagamentos)}
              <Typography>{totalVendas} vendas</Typography>
            </td>
          </tr>
        </table>
      </div>
    );
  };

  const dataData = async () => {
    try {
      setIsLoading(true);

      let body = { maquinaId: id };
      body.dataInicio = new Date(dataInicio.slice(0, 11) + "T00:00:00.000Z");
      body.dataFim = new Date(dataFim.slice(0, 11) + "T23:59:00.000Z");

      const [estornosVal, taxasVal, pagamentosVal, cashVal] = await Promise.all(
        [
          axios.post(
            `${process.env.REACT_APP_SERVIDOR}/relatorio-04-estornos-adm`,
            body,
            {
              headers: {
                "x-access-token": token,
                "content-type": "application/json",
              },
            }
          ),
          axios.post(
            `${process.env.REACT_APP_SERVIDOR}/relatorio-02-taxas-adm`,
            body,
            {
              headers: {
                "x-access-token": token,
                "content-type": "application/json",
              },
            }
          ),
          axios.post(
            `${process.env.REACT_APP_SERVIDOR}/relatorio-03-pagamentos-adm`,
            body,
            {
              headers: {
                "x-access-token": token,
                "content-type": "application/json",
              },
            }
          ),
          axios.post(
            `${process.env.REACT_APP_SERVIDOR}/relatorio-01-cash-adm`,
            body,
            {
              headers: {
                "x-access-token": token,
                "content-type": "application/json",
              },
            }
          ),
        ]
      );
      setEstornos({
        SOMA: estornosVal?.data?.valor || "0",
      });

      setTaxas({
        PIX: taxasVal.data?.pix || "0",
        CREDITO: taxasVal.data?.credito || "0",
        DEBITO: taxasVal.data?.debito || "0",
      });

      setPagamentos({
        PIX: pagamentosVal.data.pix || "0",
        CREDITO: pagamentosVal.data.credito || "0",
        DEBITO: pagamentosVal.data.debito || "0",
        ESPECIE: cashVal.data?.valor || "0",
      });

      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);

      setNotiMessage({
        type: "error",
        message: "A sua sessão expirou, para continuar faça login novamente.",
      });
    }
  };

  return (
    <div style={{ minHeight: "100vh", paddingRight: "15px" }}>
      {isLoading && <LoadingAction />}
      <div className="Cliente_WarningMsgSpan">
        <span>{dataUser.warningMsg}</span>
      </div>
      <div className="Relatorio_main">
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <div
            className="Relatorio_staBlockTitle"
            style={{ width: "100%", alignItems: "center" }}
          >
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Text color="white" fontSize="24px">
                Relatório
              </Text>
              <Button
                onClick={() => {
                  navigate(
                    `${links.CLIENTES_MAQUINAS_FORNECEDOR_SEARCH}/${id}`,
                    {
                      state: location.state,
                    }
                  );
                }}
                style={{ marginTop: "5px" }}
              >
                <span>VOLTAR</span>
              </Button>
            </div>
            <span style={{ color: "white" }}>Relatório Máquina: {id}</span>
            <br />
            <span style={{ color: "grey", fontSize: "15px" }}>
              {moment.utc(new Date(dataInicio)).format("DD/MM/YYYY")} -{" "}
              {moment.utc(new Date(dataFim)).format("DD/MM/YYYY")} -{" "}
              {maquinaInfos.nome}
            </span>
            <div style={{ color: "white" }}>
              Gerado em: {moment(new Date()).format("DD/MM/YYYY HH:mm")}
            </div>
            <Button onClick={handleGeneratePdf}>Gerar PDF</Button>
          </div>
        </div>
      </div>
      <Flex width="100%" flexDirection="column" marginLeft="15px">
        <Text fontSize="32px" color="white">
          Pagamentos
        </Text>
        <TableContainer>
          <Table size="sm">
            <Thead>
              <Tr>
                <Th borderColor="#1F2630">Pix</Th>
                <Th borderColor="#1F2630">Espécie</Th>
                <Th borderColor="#1F2630">Crédito</Th>
                <Th borderColor="#1F2630">Débito</Th>
              </Tr>
            </Thead>
            <Tbody>
              <Tr>
                <Td color="white" borderColor="#1F2630">
                  {new Intl.NumberFormat("pt-BR", {
                    style: "currency",
                    currency: "BRL",
                  }).format(pagamentos?.PIX)}
                </Td>
                <Td color="white" borderColor="#1F2630">
                  {new Intl.NumberFormat("pt-BR", {
                    style: "currency",
                    currency: "BRL",
                  }).format(pagamentos?.ESPECIE)}
                </Td>
                <Td color="white" borderColor="#1F2630">
                  {new Intl.NumberFormat("pt-BR", {
                    style: "currency",
                    currency: "BRL",
                  }).format(pagamentos?.CREDITO)}
                </Td>
                <Td color="white" borderColor="#1F2630">
                  {new Intl.NumberFormat("pt-BR", {
                    style: "currency",
                    currency: "BRL",
                  }).format(pagamentos?.DEBITO)}
                </Td>
              </Tr>
            </Tbody>
          </Table>
        </TableContainer>
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            marginTop: "20px",
          }}
        >
          <DonutChart
            data={[
              {
                name: "Pix",
                y: Number(pagamentos?.PIX),
              },
              {
                name: "Espécie",
                y: Number(pagamentos?.ESPECIE),
              },
              {
                name: "Crédito",
                y: Number(pagamentos?.CREDITO),
              },
              {
                name: "Débito",
                y: Number(pagamentos?.DEBITO),
              },
            ]}
          />
        </div>
      </Flex>
      <Flex
        width="100%"
        flexDirection="column"
        marginLeft="15px"
        marginTop="60px"
      >
        <Text fontSize="32px" color="white">
          Estornos
        </Text>
        <TableContainer>
          <Table size="sm">
            <Thead>
              <Tr>
                <Th borderColor="#1F2630">Total de estornos</Th>
              </Tr>
            </Thead>
            <Tbody>
              <Tr>
                <Td color="white" borderColor="#1F2630">
                  {new Intl.NumberFormat("pt-BR", {
                    style: "currency",
                    currency: "BRL",
                  }).format(estornos?.SOMA)}
                </Td>
              </Tr>
            </Tbody>
          </Table>
        </TableContainer>
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            marginTop: "20px",
          }}
        >
          <DonutChart
            data={[
              {
                name: "Estornos",
                y: Number(estornos?.SOMA),
              },
            ]}
          />
        </div>
      </Flex>
      <div style={{ display: "none" }}>
        <ContentPdf />
      </div>
    </div>
  );
};

export default RelatorioAdmin;
